<template>
    <div id="amigos" class="amigos" ref="amigos" style="padding-top: 56px;">
        <div class="container">
            <div class="ui grid">
                <div class="left aligned eight wide column">
                    <span class="title">{{ $t('friends.amigos') }}</span>
                </div>
                <div class="right aligned eight wide column card__bnt--convidar">
                    <div v-if="this.switch.Habilitar_convidar_amigos">
                        <router-link to="/usuarios-convidados" class="no-underline">
                            <button class="ui button secondary plus-friend"><i class="plus icon"></i>{{ getConvidar() }}</button>
                        </router-link>
                    </div>
                </div>
                <div class="sixteen wide column">
                    <div class="ui icon input" style="width: 100%; position: relative;" data-tooltip="Digite pelo menos 3 letras para buscar" data-position="top right">
                        <input
                            class="prompt"
                            type="text"
                            :placeholder="$t('friends.buscarAmigos')"
                            v-model="search"
                            style="border-radius: 10px; padding-right: 30px;"
                        >
                        <i v-if="search.length === 0" class="search icon" style="color: var(--buttons_color);"></i>
                        <i v-if="search.length > 0"
                           class="times circle icon"
                           @click="clearSearch"
                           style="cursor: pointer; color: var(--buttons_color); position: absolute; right: 10px; top: 50%; transform: translateY(-50%); pointer-events: auto;">
                        </i>
                    </div>
                </div>
                <div id="friendsAndRequests" v-if="search.length < 2">
                    <div class="sixteen wide column">
                        <div class="ui pointing secondary menu">
                            <a class="item" @click="changeTab('friends')" :class="{ active: tab === 'friends' }">
                                {{$t('friends.amigos')}} <i style="margin-left: 3px;"> ({{ data.friends.length }})</i>
                            </a>
                            <a class="item" @click="changeTab('invites')" :class="{ active: tab === 'invites' }">
                                {{$t('friends.solicitaesDeAmizade')}} <i style="margin-left: 3px;"> ({{ friendsRequests.length }})</i>
                            </a>
                            <a class="item" v-show="list_blocked.length > 0" @click="changeTab('blocked')" :class="{ active: tab === 'blocked' }">
                                {{ capitalize($t('friends.bloqueados')) }} <i style="margin-left: 3px;"> ({{ list_blocked.length }})</i>
                            </a>
                        </div>
                    </div>
                    <div v-show="tab === 'friends'" class="ui grid friendship" >
                        <div
                            class="four wide column cards-container"
                            v-for="(friend, keyFriends) in data.friends" :key="keyFriends"
                            :class="{ 'sixteen wide column': isMobile }">
                            <div class="ui card">
                                <div class="content">
                                    <div class="location-content">
                                        <div class="ui right floated suidropdown icon blocked-report">
                                            <i class="material-icons menu-floated-icon">more_vert</i>
                                            <div class="menu">
                                                <div class="item" @click="$app.complaintOpenHandler(friend.id)">
                                                    <i class="flag icon"></i> {{ capitalize($t('friends.denunciar')) }}
                                                </div>
                                                <div class="item" @click="blockUser(friend)">
                                                    <i class="ban icon"></i> {{ capitalize($t('friends.bloquear')) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="header">
                                            <span class="location-online" v-if="friend.online">
                                                <i class="material-icons">location_on</i>
                                                <span @click="goToFriend(friend.ambient)" >{{ friend.ambient }}</span>
                                            </span>
                                            <span class="location-offline" v-else>
                                                <i class="material-icons">location_on</i>
                                                Offline
                                            </span>
                                        </div>
                                        <div class="description">
                                            <div class="user-information">
                                                <router-link :to="`/profile/${friend.id}`" class="no-underline">
                                                    <img
                                                        class="ui tiny circular image"
                                                        :src="friend.avatar || `https://ui-avatars.com/api/?rounded=true&background=random&name=${friend.name}`"
                                                        @error="handleImageError($event, `https://ui-avatars.com/api/?rounded=true&background=random&name=${friend.name}`)"
                                                        alt="Avatar">
                                                    <div class="texts">
                                                        <span class="name">{{ formatName(friend.name) }}</span>
                                                        <span class="nickname">@{{friend.nickname}}</span>
                                                    </div>
                                                </router-link>
                                                <div class="button-action">
                                                    <button
                                                        class="ui button message-custom"
                                                        v-on:click="openPrivateMessage(friend.id)">
                                                        <i class="chat icon"></i>
                                                        {{ $t('notification.messages') }}
                                                    </button>
                                                    <button
                                                        v-on:click="requestRemoveFriend(friend.id)"
                                                        class="inverted primary ui button">
                                                        <i class="times icon"></i>
                                                        {{ $t('friends.desfazerAmizade') }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="tab === 'invites'" class="ui grid invites-container">
                        <div
                            class="four wide column cards-container"
                            v-for="(friend, keyFriends) in friendsRequests" :key="keyFriends"
                            :class="{ 'sixteen wide column': isMobile }">
                            <div class="ui card">
                                <div class="content">
                                    <div class="location-content">
                                        <div class="ui right floated suidropdown icon blocked-report">
                                            <i class="material-icons menu-floated-icon">more_vert</i>
                                            <div class="menu">
                                                <div class="item" @click="$app.complaintOpenHandler(friend.id)">
                                                    <i class="flag icon"></i> {{ capitalize($t('friends.denunciar')) }}
                                                </div>
                                                <div class="item" @click="blockUser(friend)">
                                                    <i class="ban icon"></i> {{ capitalize($t('friends.bloquear')) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="header">
                                            <span class="location-online" v-if="friend.online">
                                                <i class="material-icons">location_on</i>
                                                <span @click="goToFriend(friend.ambient)" >{{ friend.ambient }}</span>
                                            </span>
                                            <span class="location-offline" v-else>
                                                <i class="material-icons">location_on</i>
                                                Offline
                                            </span>
                                        </div>
                                        <div class="description">
                                            <div class="user-information">
                                                <router-link :to="`/profile/${friend.id}`" class="no-underline">
                                                    <img
                                                        class="ui tiny circular image"
                                                        :src="friend.avatar || `https://avatar.iran.liara.run/username?username=${friend.name}`"
                                                        @error="handleImageError($event, `https://avatar.iran.liara.run/username?username=${friend.name}`)"
                                                        alt="Avatar">
                                                    <div class="texts">
                                                        <span class="name">{{ formatName(friend.name) }}</span>
                                                        <span class="nickname">@{{friend.nickname}}</span>
                                                    </div>
                                                </router-link>
                                                <div class="button-action">
                                                    <button v-on:click="acceptFriendRequest(friend.id)"
                                                            class="ui button message-custom">
                                                        {{ capitalize($t('friends.aceitar')) }}
                                                    </button>
                                                    <button v-on:click="declineFriendRequest(friend.id)"
                                                            class="inverted primary ui button">
                                                        {{ capitalize($t('friends.negar')) }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="tab === 'blocked'" class="ui grid invites-container">
                        <div
                            class="four wide column cards-container"
                            v-for="(friend, keyFriends) in list_blocked" :key="keyFriends"
                            :class="{ 'sixteen wide column': isMobile }">
                            <div class="ui card">
                                <div class="content">
                                    <div class="location-content">
                                        <div class="description">
                                            <div class="user-information">
                                                <router-link :to="`/profile/${friend.id}`" class="no-underline">
                                                    <img
                                                        class="ui tiny circular image"
                                                        :src="friend.avatar || `https://avatar.iran.liara.run/username?username=${friend.name}`"
                                                        @error="handleImageError($event, `https://avatar.iran.liara.run/username?username=${friend.name}`)"
                                                        alt="Avatar">
                                                    <div class="texts">
                                                        <span class="name">{{ formatName(friend.name) }}</span>
                                                        <span class="nickname">@{{friend.nickname}}</span>
                                                    </div>
                                                </router-link>
                                                <div class="button-action">
                                                    <button v-on:click="unlockRequest(friend)"
                                                            class="inverted primary ui button">
                                                        {{ capitalize($t('mural.desbloquearUsuario')) }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="searchFriends" class="ui grid vt-container-100" v-if="search.length > 2">
                    <div id="notFound" class="ui one column centered grid center aligned" style="padding-top: 40px;" v-if="searchStatus === 'notFound'">
                        <div class="column center aligned" style="padding-bottom: 0;">
                            <i class="big grey search minus icon"></i>
                        </div>
                        <div class="column text-center" style="padding-bottom: 0px;">
                            <span class="title">{{ $t('friends.titleNotFound') }}</span>
                        </div>
                        <div class="column text-center" style="padding-top: 0px;">
                            <span>{{ $t('friends.titleNotDescripion') }}</span>
                        </div>
                    </div>
                    <div class="sixteen wide column text-search" v-if="friendsResult.friends.length > 0">
                        <span>{{ $t('friends.resultadoAmigos')}}</span>
                    </div>
                    <div class="ui grid vt-container-100" v-if="friendsResult.friends.length > 0">
                        <div
                        class="four wide column cards-container"
                        v-for="(friend, keyFriends) in friendsResult.friends" :key="keyFriends"
                        :class="{ 'sixteen wide column': isMobile }">
                        <div class="ui card">
                            <div class="content">
                                <div class="location-content">
                                    <div class="ui right floated suidropdown icon blocked-report">
                                        <i class="material-icons menu-floated-icon">more_vert</i>
                                        <div class="menu">
                                            <div class="item" @click="$app.complaintOpenHandler(friend.id)">
                                                <i class="flag icon"></i> {{ capitalize($t('friends.denunciar')) }}
                                            </div>
                                            <div class="item" @click="blockUser(friend)">
                                                <i class="ban icon"></i> {{ capitalize($t('friends.bloquear')) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="header">
                                            <span class="location-online" v-if="friend.online">
                                                <i class="material-icons">location_on</i>
                                                <span @click="goToFriend(friend.ambient)" >{{ friend.ambient }}</span>
                                            </span>
                                        <span class="location-offline" v-else>
                                            <i class="material-icons">location_on</i>
                                            Offline
                                        </span>
                                    </div>
                                    <div class="description">
                                        <div class="user-information">
                                            <router-link :to="`/profile/${friend.id}`" class="no-underline">
                                                <img
                                                    class="ui tiny circular image"
                                                    :src="friend.avatar || `https://ui-avatars.com/api/?rounded=true&background=random&name=${friend.name}`"
                                                    @error="handleImageError($event, `https://ui-avatars.com/api/?rounded=true&background=random&name=${friend.name}`)"
                                                    alt="Avatar">
                                                <div class="texts">
                                                    <span class="name">{{ formatName(friend.name) }}</span>
                                                    <span class="nickname">@{{friend.nickname}}</span>
                                                </div>
                                            </router-link>
                                            <div class="button-action">
                                                <button
                                                    class="ui button message-custom"
                                                    v-on:click="openPrivateMessage(friend.id)">
                                                    <i class="chat icon"></i>
                                                    {{ $t('notification.messages') }}
                                                </button>
                                                <button
                                                    v-on:click="requestRemoveFriend(friend.id)"
                                                    class="inverted primary ui button">
                                                    <i class="times icon"></i>
                                                    {{ $t('friends.desfazerAmizade') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="sixteen wide column text-search" v-if="friendsResult.nonFriends.length > 0">
                        <span>{{ $t('friends.resultadoAmigos')}}</span>
                    </div>
                    <div class="ui grid vt-container-100" v-if="friendsResult.nonFriends.length > 0">
                        <div
                        class="four wide column cards-container"
                        v-for="(friend, keyFriends) in friendsResult.nonFriends" :key="keyFriends"
                        :class="{ 'sixteen wide column': isMobile }">
                        <div class="ui card">
                            <div class="content">
                                <div class="location-content">
                                    <div class="ui right floated suidropdown icon blocked-report">
                                        <i class="material-icons menu-floated-icon">more_vert</i>
                                        <div class="menu">
                                            <div class="item" @click="$app.complaintOpenHandler(friend.id)">
                                                <i class="flag icon"></i> {{ capitalize($t('friends.denunciar')) }}
                                            </div>
                                            <div class="item" @click="blockUser(friend)">
                                                <i class="ban icon"></i> {{ capitalize($t('friends.bloquear')) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="header">
                                            <span class="location-online" v-if="friend.online">
                                                <i class="material-icons">location_on</i>
                                                <span @click="goToFriend(friend.ambient)" >{{ friend.ambient }}</span>
                                            </span>
                                        <span class="location-offline" v-if="!friend.online || !friend.ambient">
                                            <i class="material-icons">location_on</i>
                                            Offline
                                        </span>
                                    </div>
                                    <div class="description">
                                        <div class="user-information">
                                            <router-link :to="`/profile/${friend.id}`" class="no-underline">
                                                <img
                                                    class="ui tiny circular image"
                                                    :src="friend.avatar || `https://ui-avatars.com/api/?rounded=true&background=random&name=${friend.name}`"
                                                    @error="handleImageError($event, `https://ui-avatars.com/api/?rounded=true&background=random&name=${friend.name}`)"
                                                    alt="Avatar">
                                                <div class="texts">
                                                    <span class="name">{{ formatName(friend.name) }}</span>
                                                    <span class="nickname">@{{friend.nickname}}</span>
                                                </div>
                                            </router-link>
                                            <div class="button-action">
                                                <button
                                                    v-on:click="requestAddFriend(friend.id)"
                                                    v-if="!friend.requested"
                                                    id="addFriend"
                                                    class="ui button message-custom">
                                                    <i class="icon plus"></i>
                                                    {{ $t('friends.convidarAmigo') }}
                                                </button>
                                                <button
                                                    v-on:click="requestAddFriend(friend.id)"
                                                    v-if="friend.requested"
                                                    id="addFriend"
                                                    class="inverted primary ui button disabled">
                                                    {{ $t('friends.requisicaoPendente') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import collect from 'collect.js';
import {mapActions, mapGetters} from "vuex";
import { applyDarkenedColorOnHoverWithCSSVar, isMobile, capitalize } from "@/helper";

export default {
    name: 'Friends',
    computed: {
        ...mapGetters([
            'logged', 'token', 'switch', 'sessions'
        ]),
        isMobile() {
            return isMobile(); // Chama a função isMobile do helper
        }
    },
    watch: {
        search(value) {
            clearTimeout(this.searchTimeout); // Limpa o timeout anterior

            if (value.length >= 3) {
                // Inicia um novo timeout
                this.searchTimeout = setTimeout(() => {
                    this.searchFriend();
                }, 1000); // Atraso de 1 segundos
            }
        },
    },
    data() {
        return {
            tab: 'friends',
            list_blocked: [],
            total_blocked: 0,
            search: '',
            searchCompleated:"",
            friendsResult: {
                friends : [],
                nonFriends: []
            },
            searchTimeout: null,
            friendsRequests: [],
            data: {
                friendsCount: 0,
                friends: [],
            },
            searchStatus: 'initial',
            showBlocked: false,
            userToBlock: {},
            friendToReport: {},
            privateMessagesUsers: [],   // usuarios no inbox
            is_end: false,              // pagination: para checar se chegou ao fim do de conversas
            limit: null,                // pagination: para saber qual é o limit vindo do back
            offset: 0                  // pagination: para o total de conversas (inbox) carregadas ate o momento
        };
    },

    methods: {
        ...mapActions([
            'update_user_friends'
        ]),
        changeTab(tabName) {
            this.tab = tabName;
            setTimeout(() => {
                window.$('.blocked-report').suidropdown();
            }, 500)
        },
        unlockRequest(blocked) {
            this.userToBlock = blocked;
            this.$app.modal('confirmation', 'Atenção!', 'Deseja realmente desbloquear esse usuário?', this.unlockRequestResponse);
        },
        unlockRequestResponse(r) {
            let friend = this.userToBlock;

            if (r === 1) {
                this.$app.wait(true);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.post(this.$url_api + 'api/unBlockUser/' + friend.id, null, {headers: headers}).then(response => this.unlockResponse(response, friend.id)).catch(error => this.unlockError(error.response));
            }
        },
        unlockResponse(obj, id) {
            if (obj.data.status == 'success') {
                this.total_blocked--;
                for (let o in this.list_blocked) {
                    if (this.list_blocked[o].id == id) {
                        this.list_blocked.splice(o, 1);
                    }
                }
                this.blockedRequest()
                this.loadFriend()
                this.loadRequest()
                this.$app.modal('success', 'Sucesso!', obj.data.data, null, null, null, "OK");
            }
            this.$app.wait(false);
        },
        unlockError(obj) {
            if (obj.data.status == 'error') {
                this.$app.modal('error', 'Erro!', obj.data.message, null, null, null, "OK");
            }
            this.$app.wait(false);
        },
        capitalize(word) {
            return capitalize(word);
        },
        formatName(name) {
            if (typeof name === 'string' && name.includes(' ')) {
                const nameParts = name.split(' ');
                if (nameParts.length > 2) {
                    return `${capitalize(nameParts[0])} ${capitalize(nameParts[nameParts.length - 1])}`;
                }
                return nameParts.map(capitalize).join(' ');
            }
            return name; // Return the original name if it can't be split
        },
        clearSearch() {
            this.search = '';
        },
        handleImageError(event, placeholder) {
            event.target.src = placeholder;
        },
        getConvidar() {
            const isMobile = window.innerWidth <= 768;
            const fullText = this.$t('friends.convidarAmigos');
            const words = fullText.split(' ');

            if (isMobile) {
                return words.length > 1 ? words[0] : fullText;
            }

            return fullText;
        },
        denunciar() {
            return null;
        },
        showModalOptions(key, type) {
            let modal;

            switch (type) {
                case 'friends':
                    modal = this.$refs[`data-friend-key-${key}`];
                    break;
                case 'search':
                    modal = this.$refs[`data-search-key-${key}`];
                    break;
                default:
                    break;
            }

            if (modal) {
                if (modal[0].classList.contains('show'))
                    modal[0].classList.remove('show');
                else
                    modal[0].classList.add('show');
            }
        },
        async declineFriendRequest(userId) {
            this.$app.wait(true);
            const headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
            };

            const form_data = new FormData();
            form_data.append('friend_id', userId);

            try {
                const res = await axios.post(this.$url_api + 'api/RejectRequestFriend', form_data, {headers});
                this.requestHandlerResponse(res, userId);
            } catch (error) {
                this.requestHandlerError(error.response);
            }
        },
        async acceptFriendRequest(userId) {
            this.$app.wait(true);
            const headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
            };

            const form_data = new FormData();
            form_data.append('friend_id', userId);

            try {
                const res = await axios.post(this.$url_api + 'api/AcceptRequestFriend', form_data, {headers});
                this.blockedRequest()
                this.loadFriend()
                this.loadRequest()
                this.requestHandlerResponse(res, userId);
            } catch (error) {
                this.requestHandlerError(error.response);
            }
        },
        goToFriend(ambient) {
            const sessions = JSON.parse(JSON.stringify(this.sessions));

            const session = sessions.find(obj => {
                return obj.name === ambient;
            });

            if (session === undefined) this.$router.push({name: 'Map'});
            else this.$router.push({ name: 'Iframe', params:{sessionName: session.name,eventName:localStorage.getItem("eventmap")} });
        },
        //----- request-handler -----//
        requestHandlerResponse(obj, id) {
            try {
                if (obj.data.status == 'success') {
                    let pos = this.friendsRequests.map(function (e_) {
                        return e_.id;
                    }).indexOf(Number(id));

                    if (obj.data.message == 'Solicitação recusada') {
                        this.$app.analytics('deny_friendship');
                        this.$app.modal('success', this.$i18n.t('friends.sucesso'), this.$i18n.t('friends.solicitacaoRecusada'), null, null, null, "OK");
                    } else if (obj.data.message == 'Solicitação aceita') {
                        this.$app.analytics('accept_friendship');
                        this.data.friendsCount++;
                        let friend = this.friendsRequests[pos];
                        friend.friend = true;
                        this.data.friends.push(friend);
                        this.$app.modal('success', this.$i18n.t('friends.sucesso'), this.$i18n.t('friends.solicitacaoAceita'), null, null, null, "OK");
                    }
                    this.update_user_friends(this.data.friends);
                    this.friendsRequests.splice(pos, 1);
                    this.$forceUpdate()
                }
                this.$app.wait(false);
            }catch (e){
                console.log(e)
            }
        },
        requestHandlerError(obj) {
            if (obj.data.message == 'erro_desconhecido') {
                let str = '';
                for (let o in obj.data.errors) {
                    str += obj.data.errors[o] + '\n';
                }
                this.$app.modal('error', this.$i18n.t('friends.erro'), str, null, null, null, "OK");
            }
            this.$app.wait(false);
        },
        //----- \request-handler\ -----//
        //----- auto-complete -----//
        autocomplete_clear_all() {
            let autocomplete_count_all = document.getElementsByClassName('auto-complete').length;
            if (autocomplete_count_all > 0) {
                for (let autocomplete_t = 0; autocomplete_t < autocomplete_count_all; autocomplete_t++) {
                    document.getElementsByClassName('auto-complete')[autocomplete_t].remove();
                }
            }
        },
        autocomplete_remove_active(x) {
            for (let i = 0; i < x.length; i++)
                x[i].classList.remove("auto-complete-active");
        },
        autocomplete_add_active(x, e) {
            if (!x) return false;
            this.autocomplete_remove_active(x);
            if (e.target.dataset.currentFocus >= x.length) e.target.dataset.currentFocus = 0;
            if (e.target.dataset.currentFocus < 0) e.target.dataset.currentFocus = (x.length - 1);
            x[e.target.dataset.currentFocus].classList.add("auto-complete-active");
        },
        searchFriend() {
            let vm = this;
            if (this.search != '') {
                this.$app.wait(true);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.get(this.$url_api + 'api/SearchParticipants', {
                    headers: headers,
                    params: {search: this.search}
                }).then(response => {
                    if(response.data.data.length > 0) {
                        vm.total_search = response.data.data.length;
                        const friendsCollection = collect(response.data.data);
                        vm.friendsResult.friends = friendsCollection.where('friend', true).all();
                        vm.friendsResult.nonFriends = friendsCollection.where('friend', false).all();
                        vm.searchStatus = 'found';
                        setTimeout(() => {
                            window.$('.blocked-report').suidropdown();
                        }, 500)
                    } else {
                        vm.total_search = 0;
                        vm.friendsResult.friends = [];
                        vm.friendsResult.nonFriends = [];
                        vm.searchStatus = 'notFound';
                    }
                    this.$app.wait(false);
                }).catch(error => this.searchFriendError(error.response));
            } else {
                this.searchCompleated="";
                this.autocomplete_clear_all();
                this.total_search = 0;
                this.friendsResult = [];
            }
        },
        searchFriendResponse(obj) {
            if (obj.data.status == 'success') {

                this.$app.analytics('search_friend');

                this.autocomplete_clear_all();
                this.total_search = obj.data.data.length;
                this.friendsResult = obj.data.data;
                this.searchCompleated = this.$i18n.t('friends.noResults')+'"'+this.search+'"';
            }

        },
        searchFriendError(obj) {
            obj;
            this.$app.wait(false);
        },
        //----- \search-friends\ -----//
        //----- load-friends -----//
        loadFriend() {
            // this.loading = true;
            this.$app.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.get(this.$url_api + 'api/GetFriends', {headers: headers})
                    .then(response => {
                            this.loadFriendResponse(response);
                            setTimeout(() => {
                                window.$('.blocked-report').suidropdown();
                            }, 500)
                        })
                    .catch(error => this.loadFriendError(error.response));
        },
        loadFriendResponse(obj) {
            if (obj.data.status == 'success') {
                this.data.friendsCount = obj.data.friends.length;
                this.data.friends = obj.data.friends;
            }

            this.$app.wait(false);
        },
        loadFriendError(obj) {
            obj;
            this.$app.wait(false);
        },
        //----- \load-friends\ -----//
        //----- load-request -----//
        loadRequest() {
            this.$app.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.get(this.$url_api + 'api/RequestsFriend', {headers: headers})
                    .then(response => this.loadRequestResponse(response))
                    .catch(error => this.loadRequestError(error.response));
        },
        loadRequestResponse(obj) {
            if (obj.data.status == 'success') {
                this.friendsRequests = obj.data.requests;
            }
            this.$app.wait(false);
        },
        loadRequestError(obj) {
            obj;
            this.$app.wait(false);
        },
        //----- \load-request\ -----//
        //----- requestSend-friends -----//
        requestSendHandler(e) {
            e.preventDefault();

            //aqui juligo
            this.e_temp = {
                id: e.currentTarget.dataset.id,
                v: e.currentTarget.dataset.v,
                index: e.currentTarget.dataset.index
            };
            //this.dataset_actual = e.currentTarget.dataset;
            //this.e_target_actual = e;

            if (this.e_temp.v == '1')
                this.$app.modal('confirmation', 'Atenção!', 'Deseja realmente remover este amigo?', this.requestSendHandlerResponse);
            else
                this.requestSendHandlerResponse(1);


        },
        requestSendHandlerResponse(r) {
            if (r === 1) {
                this.$app.wait(true);

                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                let form_data = new FormData();
                form_data.append('friend_id', this.e_temp.id);

                axios.post(this.$url_api + 'api/' + ((this.e_temp.v == '0') ? 'AddFriend' : 'RemoveFriend'), form_data, {headers: headers}).then(response => this.requestSendResponse(response)).catch(error => this.requestSendError(error.response));
            }
        },
        toggleFriendStatus(friend) {
            if (friend.friend) {
                this.requestRemoveFriend(friend.id);
                return;
            }

            this.requestAddFriend(friend.id);
        },
        requestAddFriend(id) {
            this.$app.requestAddFriend(id);
            this.$app.modal('success', this.$i18n.t('friends.sucesso'), this.$i18n.t('friends.conviteFeito'), null, null, null, "OK");
            this.searchFriend();
        },
        requestRemoveFriend(id) {
            this.$app.wait(true);

            /*let alreadyFriends = this.data.friends.find((friend) => friend.id === id);

            if (!alreadyFriends) {
                console.log("Dá não!")
                return;
            }*/

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            let form_data = new FormData();
            form_data.append('friend_id', id);

            axios.post(`${this.$url_api}api/RemoveFriend`, form_data, {headers: headers})
                    .then(response => this.requestRemoveFriendResponse(response, id))
                    .catch(error => this.$app.requestToggleFriendStatusError(error.response));
        },
        requestRemoveFriendResponse(response, friendId) {
            this.$app.analytics('remove_friend');
            let friendIndex = this.data.friends.findIndex(({id}) => id === friendId);

            this.data.friendsCount--;
            this.data.friends.splice(friendIndex, 1);

            this.$app.modal('success', this.$i18n.t('friends.sucesso'), this.$i18n.t('friends.aAmizadeFoiDesfeita'), null, null, null, "OK");

            this.$app.wait(false);
        },
        //----- \requestSend-friends\ -----//
        //----- blocked -----//
        //----- blocked -----//
        blockedRequest() {
            this.$app.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.get(this.$url_api + 'api/blockedUsers', {headers: headers}).then(response => this.blockedResponse(response)).catch(error => this.blockedError(error.response));
        },
        blockedResponse(obj) {
            if (obj.data.data.length > 0) {
                this.total_blocked = obj.data.data.length;
                this.list_blocked = obj.data.data;
            }
            this.$app.wait(false);
        },
        blockedError(obj) {
            obj;
            this.$app.wait(false);
        },
        //----- \blocked\ -----//
        //----- blockUser -----//
        blockUser(friend) {
            this.userToBlock = friend;
            this.$app.modal(
                'confirmation',
                this.$i18n.t('friends.atencao'),
                this.$i18n.t('friends.desejaRealmenteBloquear'),
                this.blockUserConfirmation);
        },
        blockUserConfirmation(r) {
            let friend = this.userToBlock;

            if (r === 1) {
                this.$app.wait(true);
                let headers = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access': 'application/json',
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                };
                axios.post(this.$url_api + 'api/blockUser/' + friend.id, null, {headers: headers}).then(response => this.blockUserResponse(response, friend)).catch(error => this.blockUserError(error.response));
            }
        },
        blockUserResponse(obj, friend) {
            if (obj.data.status == 'success') {
                this.$app.analytics('block_user');
                this.blockUserProcess(friend)
                this.$app.modal('success', this.$i18n.t('friends.sucesso'), obj.data.data, null, null, null, "OK");
                this.blockedRequest()
                this.loadFriend()
                this.loadRequest()
            }
            this.$app.wait(false);
        },
        blockUserProcess(friend){
            let friendIndex = this.data.friends.findIndex(({id}) => id === friend.id);
            this.data.friendsCount--;
            this.data.friends.splice(friendIndex, 1);
            this.showBlocked = true;
        },
        blockUserError(obj) {
            if (obj.data.status == 'error') {
                this.$app.modal('error', this.$i18n.t('friends.erro'), obj.data.message, null, null, null, "OK");
            }
            this.$app.wait(false);
        },
        displayFriends() {
            if (!this.switch.Habilitar_Amigos) {
                this.$router.push({name:'Map'});
            }
        },
        openPrivateMessage(friend_id) {
            this.$app.modal_private_messages = true;
            this.$app.private_messages_friend_id = Number(friend_id);
            this.$app.refreshNotificationPrivateMessages(friend_id);     // retira a notificacao
            // find index on private messages to set unread to 0
            if( this.privateMessagesUsers.length > 0 ) {
                let index = Object.keys(this.privateMessagesUsers).find(x => this.privateMessagesUsers[x].id === friend_id);
                if( this.privateMessagesUsers[index] ) {
                    this.privateMessagesUsers[index].inbox.unread = 0;
                }
            }
        },
        loadInbox(offset = 0) {
            this.$app.wait(true);
            let form_data   = new FormData();
            form_data.append('offset', offset);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.post(this.$url_api + 'api/pm/loadInbox', form_data, {headers: headers})
                    .then(response => {this.loadInboxResponse(response)})
                    .catch(error => this.loadInboxError(error.response));
        },
        loadInboxResponse(obj) {
            // console.log('INBOX => ', obj.data);
            let more_inbox      = null;
            this.offset         = obj.data.pagination.offset;
            this.limit          = (this.limit) ? this.limit : obj.data.pagination.limit;
            this.is_end         = obj.data.pagination.is_end;

            // caso nao seja o primeiro load
            if( this.offset > this.limit ) {
                more_inbox      = obj.data.users;
                for(let i=0; i<more_inbox.length; i++) {
                    this.privateMessagesUsers.push(more_inbox[i]);
                }
            }
            // ... é o primeiro load
            else {
                this.privateMessagesUsers   = obj.data.users;
            }

            this.$app.wait(false);
        },
        loadInboxError(obj) {
            console.log('loadInboxError => ', obj);
            this.$app.wait(false);
        },
    },
    mounted() {
        // this.$app.analytics('visit_friends', {});
        this.displayFriends();
        this.loadFriend();
        this.blockedRequest();
        this.loadRequest();
        this.loadInbox();
        applyDarkenedColorOnHoverWithCSSVar('plus-friend', '--buttons_color', 10);
        applyDarkenedColorOnHoverWithCSSVar('message-custom', '--buttons_color', 10);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.autocomplete_clear_all);
    },
}
</script>
<style lang="scss" scoped>
#amigos {
    .ui.button.primary {
        background-color: var(--buttons_color) !important; /* Altere a cor aqui */
        border-color: var(--buttons_color) !important;
    }

    .ui.inverted.button.primary {
        background-color: #ffffff !important;
        border-color: var(--buttons_color) !important;
        color: var(--buttons_color) !important;
        &:hover {
            background-color: var(--buttons_color) !important;
            border-color: var(--buttons_color) !important;
            color: #ffffff !important;
        }
    }

    .title {
        font-size: 2rem;
        color: var(--buttons_color);
        font-family: 'Exo';
        font-weight: 500;
    }

    .card__bnt--convidar {
        justify-content: flex-end;
        display: flex;
        text-decoration: none;
    }

    .secondary {
        display: flex;
        justify-content: space-between;
        align-items: center; /* Centraliza o texto verticalmente */

        .item {
            flex: 1;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center; /* Garante a centralização vertical dentro de cada item */
        }

        &.menu {
            color: var(--buttons_color);
            background-color: transparent !important;
        }

        a {
            color: var(--buttons_color);
        }
    }

    .friendship {
        padding-top: 28px;
        width: 100%;
        padding-right: 0;
        margin-top: 0;
    }

    .invites-container {
        padding-top: 28px;
        width: 100%;
        padding-right: 0;
        margin-top: 0;
    }



    .cards-container {
        padding-right: 0;
        .four.wide.column.cards-container:nth-child(4) {
            padding-right: 0 !important;
        }
    }

    .card {
        // max-width: auto;
        min-height: 280px;
        border-radius: 18px;
        width: 100%;
        background: #FFF;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
        .header {
            display: flex;
            align-items: center;
        }
        &:hover {
            transform: translateY(-3px);
            z-index: 10; /* Traz o card para frente */
            position: relative;
        }
        .location-online {
            cursor: pointer;
            color: var(--buttons_color);
            text-align: center;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .material-icons {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal
            }
            &:hover {
                text-decoration: underline;
            }
        }

        .location-offline {
            color: #B1B1B1;
            text-align: center;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .material-icons {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal
            }
            &:hover {
                text-decoration: none !important;
            }
        }

        .menu-floated-icon {
            color: #CDCDCD;
            font-family: "Material Icons";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        .content {
            padding: 34px 32px 34px 32px;
            max-width: 100%;
        }

        .user-information {
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            .texts {
                padding-top: 18px;
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                text-align: center;
                .name {
                    color: var(--fonts_color);
                    font-family: "Open Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    width: 100%;
                }
                .nickname {
                    color: var(--fonts_color_2nd);
                    font-family: "Open Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 100%;
                    margin: 0;
                }
            }
            .button-action {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-top: 24px;
                gap: 12px;
                width: 100%;
                .basic {
                    background-color: var(--headers_color);
                }
                .message-custom {
                    background-color: var(--buttons_color);
                    color: var(--headers_color);
                }
                button {
                    border-radius: 10px;
                }
            }
        }
    }

    button {
        border-radius: 10px;
        &.secondary {
            background-color: var(--buttons_color);
            color: var(--headers_color);
            font-weight: 500;
            font-size: 1rem;
            font-family: 'Open Sans';
        }
    }

    .prompt {
        &:focus {
            border-color: var(--buttons_color);
        }
    }

    #friendsAndRequests, .vt-container-100 {
        width: 100%;
        padding-right: 0;
    }

    #notFound {
        span {
            font-family: "Open Sans";
        }
        .title {

        }
    }

    .text-search {
        padding-top: 24px;
        padding-bottom: 18px;
        span {
            font-weight: 600;
            font-family: 'Open Sans';
            font-size: 18px;
        }
    }

    .no-underline {
        text-decoration: none; /* Remove o underline de todos os router-links */
    }

    // MOBILE
    @media only screen and (max-width: 768px) {
        .card {
            width: 100%;
        }
        .friendship {
            min-width: 100%;
        }
    }
}
</style>