<template>
    <div v-if="started" v-show="(is_complete_quest && !quest.quest.show_message) ? false : true" id="quest_modal_wrap" :class="is_fullscreen?'fullscreen':''">
<span class="quest_modal_overlay" />
        <div :class="(current_step && current_step.action=='form') || current_step && current_step.action=='quiz'?'form_width quest_modal':'quest_modal'">
            <div class="quest_modal_inner">
<!--                <button class="close" v-if="current_step_rewards && !is_fullscreen" v-on:click="closeRewards"><i class="fas fa-times"></i></button>-->
<!--                <button class="close" v-else-if="is_complete_quest && !is_fullscreen" v-on:click="closeComplete"><i class="fas fa-times"></i></button>-->
<!--                <button class="close" v-else-if="!is_fullscreen" v-on:click="closeModal"><i class="fas fa-times"></i></button>-->
                <button v-if="is_fullscreen" class="close" @click="is_fullscreen=false">
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.8555 0.25H29.75V10.1445H26.8945V5.09766L13.0156 18.9766L11.0234 16.9844L24.9023 3.10547H19.8555V0.25ZM26.8945 22.8945V13H29.75V22.8945C29.75 23.6471 29.4622 24.3112 28.8867 24.8867C28.3112 25.4622 27.6471 25.75 26.8945 25.75H7.10547C6.30859 25.75 5.6224 25.4844 5.04688 24.9531C4.51562 24.3776 4.25 23.6914 4.25 22.8945V3.10547C4.25 2.30859 4.51562 1.64453 5.04688 1.11328C5.6224 0.53776 6.30859 0.25 7.10547 0.25H17V3.10547H7.10547V22.8945H26.8945Z" fill="white" />
                    </svg>
                </button>
                <button v-if="!is_fullscreen && !current_step_rewards && current_step && current_step.action=='open_file' && !is_complete_quest" class="close isfull" @click="is_fullscreen=true">
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.8555 0.25H29.75V10.1445H26.8945V5.09766L13.0156 18.9766L11.0234 16.9844L24.9023 3.10547H19.8555V0.25ZM26.8945 22.8945V13H29.75V22.8945C29.75 23.6471 29.4622 24.3112 28.8867 24.8867C28.3112 25.4622 27.6471 25.75 26.8945 25.75H7.10547C6.30859 25.75 5.6224 25.4844 5.04688 24.9531C4.51562 24.3776 4.25 23.6914 4.25 22.8945V3.10547C4.25 2.30859 4.51562 1.64453 5.04688 1.11328C5.6224 0.53776 6.30859 0.25 7.10547 0.25H17V3.10547H7.10547V22.8945H26.8945Z" fill="white" />
                    </svg>
                </button>
                <div class="quest_modal_inner_wrap">
<!-- WELCOME -->
                    <div v-if="is_welcome" class="step_wrap quest_welcome">
                        <div class="info_wrap s-overflow-wrap">
                            <h2 class="so-exclude">{{ quest.quest.name }}</h2>}
                            <div v-if="quest.quest.show_description" class="so-overflow description">
                                {{ quest.quest.description }}
                            </div>
                            <div v-if="quest.quest.thumb" class="image_wrap">
                                <video v-if="quest.quest.thumb && quest.quest.thumb.includes('mp4')" autoplay controls preload="auto">
                                    <source :src="quest.quest.thumb" type="video/mp4">
                                </video>
                                <img v-else :src="quest.quest.thumb" alt="">
                            </div>
                            <div class="options">
                                <button type="button" @click="userProgress(true)">{{ $t('quest.start') }}</button>
                            </div>
                        </div>
                    </div>
                    <!-- /WELCOME -->
                    <!-- WARNING -->
                    <div v-else-if="is_warning" class="step_wrap centered">
                        <div class="info_wrap s-overflow-wrap">
                            <h2 class="so-exclude">{{ is_warning_title }}</h2>
                            <div class="so-overflow description">{{ is_warning_text }}</div>
                            <div class="options">
                                <button type="button" @click="closeModal">{{ $t('quest.understood') }}</button>
                            </div>
                        </div>
                    </div>
                    <!-- /WARNING -->
                    <!-- REWARDS FROM COMPLETED STEP -->
                    <div v-else-if="current_step_rewards" class="step_wrap centered">
                      <div class="info_wrap s-overflow-wrap">
                        <h2 class="so-exclude">{{ $t('quest.congratulations') }}</h2>
                        <div class="so-overflow description">
                          <span style="margin-bottom:10px;display:inline-block;">{{ $t('quest.won') }}</span>
                          <ul class="rewards_list">
                            <li v-for="(reward, keyReward) in current_step_rewards" :key="keyReward">
                              <!-- BADGES -->
                              <div v-if="reward.type == 'badge'" class="item-wrap">
                                <div v-if="reward.value.file_url" class="image-wrap">
                                  <img :src="reward.value.file_url" alt="">
                                </div>
                                <h3>{{ reward.value.name }}</h3>
                              </div>
                              <!-- /BADGES -->
                              <!-- TICKETS -->
                              <div v-if="reward.type == 'ticket_gate'" class="item-wrap">
                                <div v-if="reward.value.thumbnail" class="image-wrap">
                                  <img :src="reward.value.thumbnail" alt="">
                                </div>
                                <h3>{{ reward.value.title }}</h3>
                              </div>
                              <!-- /TICKETS -->
                              <!-- COINS -->
                              <div v-if="((reward.type == 'coins') && (coins == true))" class="item-wrap">
                                <div class="image-wrap">
                                  <img src="assets/icons/packagePayments/coins-waiting.png" alt="">
                                </div>
                                <h3>{{ reward.value }} {{ $t('quest.coins') }}</h3>
                              </div>
                              <!-- /COINS -->
                              <!-- POINTS -->
                              <div v-if="((reward.type == 'points') && (points == true))" class="item-wrap">
                                <div class="image-wrap">
                                  <img src="assets/icons/packagePayments/coins-waiting.png" alt="">
                                </div>
                                <h3>{{ reward.value }} {{ $t('quest.points') }}</h3>
                              </div>
                              <!-- /POINTS -->
                            </li>
                          </ul>
                        </div>
                        <div class="options">
                          <button type="button" @click="closeRewards">{{ $t('quest.confirm') }}</button>
                        </div>
                      </div>
                    </div>
                    <!-- /REWARDS FROM COMPLETED STEP -->
                    <!-- COMPLETED QUEST -->
                    <div v-else-if="is_complete_quest && quest.quest.show_message" class="step_wrap quest_welcome">
                        <div v-if="quest.quest.thumb_message" class="image_wrap">
                            <img :src="quest.quest.thumb_message" alt="">
                        </div>
                        <div class="info_wrap s-overflow-wrap">
                            <h2 class="so-exclude">{{ $t('quest.accomplished') }}</h2>
                            <div class="so-overflow description">
                                {{ quest.quest.message }}
                            </div>
                            <div class="options">
                                <button type="button" @click="closeComplete">OK</button>
                            </div>
                        </div>
                    </div>
                    <!-- /COMPLETED QUEST -->

                    <!-- CURRENT STEP -->
                    <div v-else-if="!is_complete_quest" class="step_wrap centered">
                        <UrlStep v-if="current_step && current_step.action==='url'" />
                        <FileStep v-else-if="current_step && current_step.action==='open_file'" />
                        <FormStep v-else-if="current_step && current_step.action==='form'" ref="formStep" />
                        <FormStep v-else-if="current_step && current_step.action==='quiz'" ref="formStep" />
                    </div>
                    <!-- /CURRENT STEP -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import FormStep from "./FormStep";
import UrlStep from "./UrlStep";
import {focusUnity} from "@/helper";
import FileStep from "@/components/Quest/FileStep.vue";

export default {
    name: 'Quest',
    // props: ['quest_id', 'quest_step_id'],
    computed: {
        ...mapGetters([
            'token', 'quest', 'sessions', 'switch', 'checkMobile'
        ]),
    },
    components: {
        FileStep,
        FormStep,
        UrlStep
    },
    data() {
        return {
            coins: false,
            points: false,
            is_welcome: false,          // check if user has any progress in the quest, if not appear the welcome message
            is_complete_quest: false,   // check if user already completed the quest (quest type one timer??)
            is_warning: false,          // check if user already completed the step or he is not in the step
            is_warning_title: false,    // set the warning title
            is_warning_text: false,     // set the warning message
            is_fullscreen: false,       // check if fullscreen is enabled
            current_step: null,         // all info about the current step
            current_step_rewards: null, // the rewards of the current step when user complete it
            started: false
        }
    },
    methods: {
        ...mapActions([
            "update_quest"
        ]),
        init() {

            // user haven't accepted the quest yet (his progress is empty)
            if( !this.quest.quest.user_quest_progress ) {
                this.is_welcome = true;
                this.started = true;
            }
            // user has already completed the quest (one timer quest type)
            else if( this.quest.quest.user_quest_progress === 'completed' ) {
                this.is_complete_quest = true;
                this.started = true;
            }
            // user has already made some progress in the quest
            else {

                // 1 - get the active user step id
                // let user_current_step   = this.quest.user_progress.filter(step => step.status === 'active');

                // 1 - check if it is the correct step that user is in
                console.log('IDS => ', this.quest.quest.user_quest_progress, this.$app.quest_step_id);
                if( this.quest.quest.user_quest_progress == this.$app.quest_step_id ) {

                    // get and set the stuff from the step
                    let current_step    = this.quest.quest.steps.filter(step => step.id == this.$app.quest_step_id);
                    this.current_step   = current_step[0];
                    console.log('current step => ', this.current_step);
                    
                    switch (this.current_step.action) {
                        case "url":
                            this.started = true;
                            console.log('ACTION URL', this.current_step.action);
                            break;
                        case "open_file":
                            this.started = true;
                            console.log('ACTION OPEN_FILE', this.current_step.action);
                            break;
                        case "quiz":
                            this.started = true;
                            console.log('ACTION QUIZ', this.current_step.action);
                            break;
                        case "form":
                            this.started = true;
                            console.log('ACTION FORM', this.current_step.action);
                            break;
                        default:
                            console.log('ACTION DEFAULT', this.current_step.action);
                            this.userProgress();
                            break;
                    }
                }

                // 3 - if not the correct step, show modal with a message saying he is not in that step...
                else {
                    this.started = true;
                    this.is_warning         = true
                    this.is_warning_title   = 'Passo Errado';
                    this.is_warning_text    = 'Você deve ir ao lugar correto!';

                    // AQUI PODE PEGAR A PARTIR DO PASSO QUE O USER TA (this.quest.quest.user_quest_progress (é a ID do passo ou NULL ou 'completed')), e ver se é um passo antes ou depois
                    // // 3.1 try to find the step in the user progress
                    // let check_step  = this.quest.user_progress.filter(step => step.step_id === this.$app.quest_step_id);
                    // this.is_warning = true

                    // // 3.2 check if user already made this step
                    // if( check_step.length && check_step[0].status == 'completed' ) {
                    //     this.is_warning_title   = 'Passo Completo!';
                    //     this.is_warning_text    = 'Este passo já está completo. Mais alguma texto muito legal aqui!';
                    // }

                    // // 3.2 if user have not made this step yet
                    // else {
                    //     this.is_warning_title   = 'Passo Incompleto!';
                    //     this.is_warning_text    = 'Você precisa completar o passo que você está antes de ir a esse!';
                    // }

                }
                
            }
        },
        userProgress(is_start = false) {
            // this.$app.wait(true);
            let headers         = {
                "Content-Type": "application/json;charset=UTF-8",
                Access: "application/json",
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
            };
            let form_data   = new FormData();
            form_data.append("quest", this.$app.quest_id);
            form_data.append("step", (is_start) ? 0 : this.$app.quest_step_id);
            console.log('this.Habilitar_Pontos, this.Habilitar_Moedas => ', this.switch.Habilitar_Pontos, this.switch.Habilitar_Moedas);
            form_data.append('points', this.switch.Habilitar_Pontos);
            form_data.append('coins', this.switch.Habilitar_Moedas);
            axios
                .post(this.$url_api + 'api/quest-user-progress', form_data, { headers:headers })
                .then(response => this.loadUserProgressResponse(response, is_start))
                .catch(error => this.loadUserProgressError(error.response));
        },
        loadUserProgressResponse(obj, is_start) {
            if(obj.data.user_quest_progress) {

                let current_step_index                  = (obj.data.user_quest_progress != 'completed') ? this.quest.quest.steps.findIndex( x => x.id === obj.data.user_quest_progress ) : null;
                let next_step_index                     = (current_step_index !== null && this.quest.quest.steps[(current_step_index+1)]) ? (current_step_index+1) : null;
                // let next_step                        = (next_step_index) ? this.quest.quest.steps[next_step_index].id : null;
                // let unity_json_data                  = {currentStep: obj.data.user_quest_progress, nextStep: next_step};
                let unity_json_data                     = {currentStep: current_step_index, nextStep: next_step_index};
                this.current_step_rewards               = this.quest.quest.rewards ? this.quest.quest.rewards[this.quest.quest.user_quest_progress] : null;
                this.quest.quest.user_quest_progress    = obj.data.user_quest_progress;
                this.$app.quest_step_id              = obj.data.user_quest_progress;
                
                this.$app.sendToUnity('SetQuestCheckOut', unity_json_data); // currentStep  // nextStep
                focusUnity(this.checkMobile);
                // console.log('JSON STEPS IDS =>', unity_json_data);
                // console.log('current index =>', current_step_index);
                // console.log('next index =>', next_step_index);
                // console.log('STEP REWARDS',this.current_step_rewards);

                if( is_start ) {
                    this.is_welcome             = false;
                    // this.init();
                    this.$app.modal_quest    = false;
                } else {
                    // pode ser que o passo não tenha recompensa
                    /*
                    if( !this.current_step_rewards ) {
                        this.$app.modal_quest    = false;
                    }
                    */
                    if (!this.current_step_rewards && this.quest.quest.user_quest_progress == 'completed') {
                        this.is_complete_quest = true;
                        if (!this.quest.quest.show_message){
                            this.closeComplete();
                        }
                    }
                    if( !this.current_step_rewards && !this.is_complete_quest) {
                        this.$app.modal_quest    = false;
                    }else{
                        this.started = true;
                    }
                    
                }
            }
            // this.$app.modal_quest    = false;
            // this.$app.wait(false);
        },
        loadUserProgressError(obj) {
            obj;
            // this.$app.wait(false);
        },
        openUrl() {
            window.open(this.current_step.action_value, '_blank').focus();
            this.userProgress();
        },
        closeRewards() {
            // console.log('close rewards');
            focusUnity(this.checkMobile);
            // checar se é o ultimo passo da missao e ele acabou de completar para abrir o completed
            if( this.quest.quest.user_quest_progress === 'completed' ) {
                this.current_step_rewards   = null;
                this.is_complete_quest      = true;
                if (!this.quest.quest.show_message){
                    this.closeComplete();
                }
            } else {
                this.$app.modal_quest    = false;
            }
        },
        checkSession()
        {
            let root_ = this;
            let session = this.sessions.find(obj => { 
                // console.log(obj.room_id, root_.quest.quest.action_value);
                return obj.room_id === Number(root_.quest.quest.action_value); 
            });
            return session;
        },
        closeComplete() {
            focusUnity(this.checkMobile);
            // console.log('close quest complete');
            // se for infinita, zera o user progress
            if( this.quest.quest.infinite ) {
                this.quest.quest.user_quest_progress = null;
            }
            // pega as actions
            if (this.quest.quest.action == "session")
            {
                // console.log('ACTION COMPLETED', this.quest.quest.action);
                // console.log('ACTION VALUE COMPLETED', this.quest.quest.action_value);
                let session__ = this.checkSession();
                // console.log('SESSION', session__);
                if ((session__ !== undefined) && (session__ !== null)) {
                    this.$router.push({name: "Iframe", params: { eventName:localStorage.getItem("eventmap"),sessionName:session__.name }});
                }
            }
            else if (this.quest.quest.action == "map")
            {
                console.log('ACTION OPEN_FILE', this.$route.name, this.quest.quest.action);
                if (this.$route.name != 'Map')
                    this.$app.link('Map');
            }
            
            this.$app.modal_quest = false;
        },
        closeModal() {
            // console.log('close modal');
            focusUnity(this.checkMobile);
            this.$app.modal_quest = false;
        }
    },
    created() {

    },
    mounted() {
        
        this.coins = this.switch.Habilitar_Moedas && this.switch.event_menu.score_moedas;
        this.points = this.switch.Habilitar_Pontos && this.switch.event_menu.score_pontos;
        // console.log('this.switch.Habilitar_Pontos => ', this.switch.Habilitar_Pontos);
        // console.log('COINS => ', this.coins);
        // console.log('POINTS => ', this.points);
        this.init();
    },
    unmounted() {
    },

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');


#quest_modal_wrap{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
}
#quest_modal_wrap .quest_modal_overlay{
    position:fixed;
    width:100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000;
    z-index: 1;
    opacity: .5;
}
#quest_modal_wrap .quest_modal.form_width{
    max-width: 600px;
}
#quest_modal_wrap .quest_modal{
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 30px;
    position: relative;
    z-index: 2;
    min-width: 300px;
    min-height: 140px;
    max-width: 820px;
    width: auto;
    display: flex;
    left: auto;
    right: auto;
    align-self: center;
    margin: 0 auto;
    max-height: 100%;
    overflow: hidden;
}
#quest_modal_wrap .quest_modal .quest_modal_inner{
    position: relative;
    width: 100%;
    /* height: 100%; */
    padding: 50px;
    box-sizing: border-box;
}
#quest_modal_wrap .quest_modal .quest_modal_inner .quest_modal_inner_wrap{
    height: 100%;
    /* overflow: hidden; */
}
#quest_modal_wrap .quest_modal .close{
    background: none;
    border: none;
    color: white;
    position: absolute;
    right: 25px;
    top: 20px;
    font-size: 30px;
    padding: 0;
    margin: 0;
    line-height: 0;
}
/*#quest_modal_wrap .quest_modal .close.isfull{
    right: 0;
    left: 360px;
    width: 40px;
}*/
#quest_modal_wrap .quest_modal .close svg{
    height: 30px;
    width: 34px;
}


/* SMART OVERFLOW */
#quest_modal_wrap .quest_modal .s-overflow-wrap{
    display: flex;
    flex-direction: column;
}
#quest_modal_wrap .quest_modal .s-overflow-wrap .so-exclude{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
#quest_modal_wrap .quest_modal .s-overflow-wrap .so-overflow{
    /* max-height: 46.2%;
    max-height: 86%; */
    overflow: auto;
    overflow-x: hidden;
    padding-right: 5px;
    scrollbar-width: thin;
    scrollbar-color: #33BCEEE5 rgba(255,255,255,.5);
}


/* COMMON */
#quest_modal_wrap .quest_modal .step_wrap{
    display: flex;
    height: 100%;
}
#quest_modal_wrap .quest_modal .info_wrap{
    width: 100%;
}
#quest_modal_wrap .quest_modal .info_wrap h2{
    font-family: 'Titillium Web', sans-serif;
    color:white;
    margin: 0;
    padding: 0;
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    font-size: 40px;
    line-height: 42px;
    padding-bottom: 20px;
    word-break: break-word;
}
#quest_modal_wrap .quest_modal .info_wrap .description{
    font-family: 'Titillium Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
}
#quest_modal_wrap .quest_modal .info_wrap .options{
    margin-top:20px;
    display: block;
    justify-content: center;
    align-items: center;
}
#quest_modal_wrap .quest_modal .info_wrap .options button,
#quest_modal_wrap .quest_modal .info_wrap .quest_button{
    border:none;
    font-family: 'Titillium Web', sans-serif;
    font-size:18px;
    font-weight: 400;
    line-height: 22px;
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: #33BCEEE5;
    padding: 15px 25px;
    display: inline-block;
}
#quest_modal_wrap .quest_modal .image_wrap{
    flex: 0 0 40%;
    max-width: 600px;
    margin: 0 auto;
}
#quest_modal_wrap .quest_modal .image_wrap img{
    /*eu*/
    /* max-width: 100%; */
    /* width: auto; */
    max-width: 100%;
    vertical-align: top;
}
#quest_modal_wrap .quest_modal .image_wrap video{
    max-width: 100%;
    vertical-align: top;
}
#quest_modal_wrap .quest_modal .step_wrap.centered{
    text-align: center;
    justify-content: center;
}

/* REWARDS LIST */
#quest_modal_wrap .quest_modal ul.rewards_list{
    list-style: none;
    padding: 0;
    margin: 0;
}
#quest_modal_wrap .quest_modal ul.rewards_list li{
    display: inline-block;
}
#quest_modal_wrap .quest_modal ul.rewards_list li .item-wrap{
    padding:10px
}
#quest_modal_wrap .quest_modal ul.rewards_list li .image-wrap{
    width: 125px;
    margin: 0 auto 20px auto;
}
#quest_modal_wrap .quest_modal ul.rewards_list li .image-wrap img{
    width: 100%;
    vertical-align: top;
}
#quest_modal_wrap .quest_modal ul.rewards_list li h3{
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    text-shadow: none;
}

/* FULLSCREEN */
#quest_modal_wrap.fullscreen{
    z-index: 1000;
}
#quest_modal_wrap.fullscreen .quest_modal_overlay{
    opacity: .8;
}
#quest_modal_wrap.fullscreen .quest_modal{
    width: 100vw;
    max-width: none;
    height: 100vh;
    max-height: none;
}
#quest_modal_wrap.fullscreen .quest_modal .info_wrap{
    width: 100%;
}
#quest_modal_wrap.fullscreen .quest_modal .info_wrap .description{
    height: 100%;
}
#quest_modal_wrap.fullscreen .quest_modal .info_wrap .description iframe{
    width: 100%;
    height:100%;
}

/* IFRAME */
#quest_modal_wrap .quest_modal .info_wrap .description iframe{
    width: 650px;
    height: 420px;
}


/* FORM */
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .manyOptions{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper{
    width: 100%;
}
#quest_modal_wrap .quest_modal .form__swiper{
    width: 100%;
    height: 100%;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper{
    /* height: calc(100% - 135px) 
    display: flex;
    flex-direction: column;*/
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .so-flex-direction{
    /* height: calc(100% - 135px) */
    display: flex;
    flex-direction: column;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper form{
    display: flex;
    flex-direction: column;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper form .swiper-slide .item-wrap{
    display: flex;
    flex-direction: column;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper {
    height: 100%;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper form{
    height: 100%;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper form .swiper-slide {
    height: 100%;
    width: 100% !important;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper form .swiper-slide .item-wrap{
    height: 100%;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper form .swiper-slide .item-wrap .description{
    height: 100%;
    /* align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column; */
}
/*esse nao tava 100% (vazio) */
/* #quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper form .swiper-wrapper{
    height: 100% !important;
} */
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .image_wrap{
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder{

}

#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .q_name__{
    margin-bottom: 20px;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder textarea{
    font-family: 'Titillium Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width:100%;
    background: #FFFFFF;
    border: 2px solid #E9E9E9;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    color: #CCCCCC;
    color: #343434;
    outline: none;
    padding: 20px;
    box-sizing: border-box;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder.radiotype{
    display: flex;
    justify-content: center;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder .radio_holder{
    margin: 0 8px;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder .radio_holder label{
    display: block;
    font-size: 16px;
    line-height: 26px;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder.checkboxtype{

}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder .checkbox_holder{

}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder .checkbox_holder label{
    background: #FFFFFF;
    border-radius: 14px;
    color: #343434;
    display: flex;
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
    align-items: flex-start;
    text-align: left;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder .checkbox_holder input[type="checkbox"]{
    margin-top:8px;
    margin-right: 8px;
}

#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder.radiotype.choose{
    display: block;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder.radiotype.choose .radio_holder{

}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder.radiotype.choose .radio_holder label{
    background: #FFFFFF;
    border-radius: 14px;
    color: #343434;
    display: flex;
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
    align-items: flex-start;
    text-align: left;
}
#quest_modal_wrap .quest_modal .info_wrap.form_wrapper .form_sw_wrapper .inputs_holder.radiotype.choose .radio_holder input[type="radio"]{
    margin-top:6px;
    margin-right: 8px;
}

#quest_modal_wrap #formPrev.swiper-button-disabled,
#quest_modal_wrap #formNext.swiper-button-disabled{
    opacity:.5;
    cursor: default;
    display: inline-block !important;
}
#quest_modal_wrap #formSend{
    background: #27bd27;
}
#quest_modal_wrap #formSend:disabled{
    opacity:.5;
    cursor: default;
}
#quest_modal_wrap .buttonHidden,
#quest_modal_wrap #formNext.swiper-button-disabled.buttonHidden{
    display:none !important;
}


@media only screen and (max-width: 1000px) {
    #quest_modal_wrap .quest_modal .info_wrap .description iframe{
        width: 100%;
        height: auto;
    }
}
@media only screen and (max-width: 600px) {
    #quest_modal_wrap
    {
        margin: 10px;
        width: calc(100% - 20px);
    }
    #quest_modal_wrap .quest_modal
    {
        max-height: 70%;
    }
    #quest_modal_wrap .quest_modal .quest_modal_inner
    {
        padding: 30px;
    }
    #quest_modal_wrap .quest_modal .info_wrap h2 {
        font-size: 24px;
        line-height: 28px;
    }
}


</style>