<template>
    <div id="quest-form" class="info_wrap s-overflow-wrap form_wrapper" style="width:100%;">
        <AnswerSheet v-if="show_answer_sheet" :form_selected="form_selected"
                     :form_questions="form_questions"
                     @close="progress"/>
        <!-- INFOS DO PASSO -->
        <div v-else-if="!form" class="form_sw_wrapper so-flex-direction">
            <h2 class="so-exclude">
                {{ $parent.current_step.name }}
            </h2>
            <div class="so-overflow description">
                {{ $parent.current_step.description }}
            </div>
            <div class="options">
                <button type="button" @click="formInit">
                    {{ $t('quest.start') }}
                </button>
            </div>
        </div>
        <!-- /INFOS DO PASSO -->

        <!-- INFOS DO FORM -->
        <div v-else class="form_sw_wrapper">
            <div v-if="quiz_error" id="quiz_error" class="options">
                <h2>Atenção!</h2>
                <div class="so-overflow description" style="white-space: pre-wrap;" v-text="quiz_error"/>
                <br>
                <button type="button" @click="restart">
                    {{ $t('quest.reply') }}
                </button>
                &nbsp;
                <button type="button" @click="exit">
                    {{ $t('quest.exit') }}
                </button>
            </div>

            <form v-else id="formForm" onsubmit="return false;">
                <input type="hidden" name="form_id" :value="form.id">
                <h2 class="so-exclude">
                    {{ $parent.current_step.name }}
                </h2>


                <div class="swiper-container form__swiper">
                    <div class="swiper-wrapper">
                        <div v-for="(question, index) in form_questions" :key="index" class="swiper-slide">
                            <div class="item-wrap">
                                <!-- <h2 class="so-exclude">{{ $parent.current_step.name }}</h2> -->
                                <div class="so-overflow description">
                                    <div ref="current-step-info">
                                        Passo {{ form__swiper ? (form__swiper.activeIndex + 1) : '' }} de
                                        {{ form_questions.length }}
                                    </div>
                                    <div class="q_name__">
                                        {{ question.name }}
                                    </div>
                                    <div v-if="question.image" class="image_wrap">
                                        <img :src="question.image" alt="">
                                    </div>

                                    <!-- TIPO TEXT -->
                                    <div v-if="question.type==='TEXT'" class="inputs_holder">
                                        <textarea maxlength="100" rows="5"
                                                  :placeholder="question.options[0].text_sugested" class="child"
                                                  :name="'question['+question.id+']'"
                                                  @keyup="validateField($event.target)"/>
                                    </div>
                                    <!-- /TIPO TEXT -->

                                    <!-- TIPO NOTA -->
                                    <div v-if="question.type==='NOTE'" class="inputs_holder radiotype">
                                        <div v-for="number, i in 11" :key="i" class="radio_holder">
                                            <label :for="'ra_'+question.id+'_'+i">
                                                <input :id="'ra_'+question.id+'_'+i" type="radio" class="child"
                                                       :name="'question['+question.id+']'" :value="i"
                                                       @click="validateField($event.target)">
                                                {{ i }}
                                            </label>
                                        </div>
                                    </div>
                                    <!-- /TIPO NOTA -->

                                    <!-- TIPO MULTIPLA ESCOLHA -->
                                    <div v-if="question.type==='M_OPTIONS'" class="inputs_holder checkboxtype">
                                        <div v-for="(opt, ii) in question.options" :key="ii" class="checkbox_holder">
                                            <label :for="'ch_'+question.id+'_'+ii">
                                                <input :id="'ch_'+question.id+'_'+ii" type="checkbox" class="child"
                                                       :name="'question['+question.id+']['+opt.id+']'" :value="opt.name"
                                                       @click="validateField($event.target)">
                                                {{ opt.name }}
                                            </label>
                                        </div>
                                    </div>
                                    <!-- /TIPO MULTIPLA ESCOLHA -->

                                    <!-- TIPO ESCOLHA -->
                                    <div v-if="question.type==='OPTIONS'" class="inputs_holder radiotype choose">
                                        <div v-for="(opt, iii) in question.options" :key="iii" class="radio_holder">
                                            <div>
                                                <!-- <input class="styled-checkbox" :id="'pet' + iii" name="pet[]" type="checkbox" value="1"> -->
                                                <input :id="'ch_'+question.id+'_'+iii" type="radio"
                                                       class="child styled-checkbox" :name="'question['+question.id+']'"
                                                       :value="opt.name" @click="validateField($event.target)">
                                                <label class="label" :for="'ch_'+question.id+'_'+iii">{{ opt.name }}</label>
                                            </div>

                                            <!-- <label :for="'ch_'+question.id+'_'+iii">
                                                <input v-on:click="validateField($event.target)" type="radio" class="child" :id="'ch_'+question.id+'_'+iii" :name="'question['+question.id+']'" :value="opt.name" />
                                                {{ opt.name }}
                                            </label> -->
                                        </div>
                                    </div>
                                    <!-- /TIPO ESCOLHA -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="options manyOptions">
                    <button id="formPrev" class="swiper-button-disabled" disabled type="button">
                        {{ $t('quest.previous') }}
                    </button>
                    <button id="formNext" class="swiper-button-disabled" disabled type="button">
                        {{ $t('quest.next') }}
                    </button>
                    <button id="formSend" class="buttonHidden" type="submit"
                            :disabled="!this.last_page || !last_page_ok" @click="submitForm">
                        {{ $t('quest.send') }}
                    </button><!-- disabled -->
                </div>
            </form>
        </div>
        <!-- /INFOS DO FORM -->
    </div>
</template>

<script>
import axios from "axios";
import Swiper from "swiper/bundle";
import {mapActions, mapGetters} from "vuex";
import AnswerSheet from "@/components/Quest/AnswerSheet.vue";


export default {
    name: 'QuestForm',
    components: {AnswerSheet},
    computed: {
        ...mapGetters([
            'token', 'quest'
        ]),
    },
    data() {
        return {
            // current_bg: null,
            form: null,
            form_correct: [],
            form_questions: null,
            form_selected: [],
            form__swiper: null,
            last_page: false,
            last_page_ok: false,
            quiz_error: null,
            restart_: false,
            show_answer_sheet: false,
        }
    },
    methods: {
        ...mapActions([
            "update_quest"
        ]),
        restart(e) {
            this.restart_ = true;
            this.form_correct = [];
            this.form_selected = [];
            this.last_page = false;
            this.last_page_ok = false;
            this.form__swiper.destroy(".form__swiper");
            this.quiz_error = null;
            this.startSwiper();

            /*
            let root_ = this;
            root_;
            setTimeout(() => {
                document.getElementById('formForm').reset();
                
                // root_.form__swiper.slideReset(0);
                // root_.form__swiper.updateProgress();
                // root_.form__swiper.updateSlides();

                
                // root_.startSwiper();

                
            }, 2500);
            */
            e.preventDefault();
        },
        exit(e) {
            this.$app.modal_quest = null;
            e.preventDefault();
        },
        onSwiper(swiper) {
            swiper;
        },
        nextButtonStatus(enabled) {
            var nextButt = document.getElementById('formNext');
            if (enabled) {
                nextButt.removeAttribute('disabled');
                nextButt.classList.remove('swiper-button-disabled');
                if (this.last_page) {
                    this.last_page_ok = true;
                }
            } else {
                nextButt.setAttribute('disabled', 'disabled');
                nextButt.classList.add('swiper-button-disabled');
                if (this.last_page) {
                    this.last_page_ok = false;
                }
            }
        },
        formInit() {
            // console.log('CURRENT STEP', this.$parent.current_step);
            // console.log('QUEST', this.$parent.quest.quest);
            this.$app.wait(true);
            let params = {
                form: this.$parent.current_step.action_value,
            };
            let headers = {
                "Content-Type": "application/json;charset=UTF-8",
                Access: "application/json",
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
            };
            axios
                .get(this.$url_api + 'api/quest-step-form', {headers: headers, params: params})
                .then(response => this.loadformInitResponse(response))
                .catch(error => this.loadformInitError(error.response));
        },
        startSwiper() {
            let ___root = this;
            setTimeout(function () {
                ___root.form__swiper = new Swiper(".form__swiper", {
                    observer: true,
                    observeParents: true,
                    loop: false,
                    autoHeight: true,
                    slidesPerView: 1,
                    allowTouchMove: false,
                    // autoplay: true,
                    // delay: 2000,
                    navigation: {
                        nextEl: "#formNext",
                        prevEl: "#formPrev",
                    },
                    on: {
                        afterInit: function () {
                            ___root.nextButtonStatus(false);
                        },
                        slideChange: function () {
                            ___root.slideChangeHandler();
                        }
                    },
                });

                ___root.slideChangeHandler();
            }, 200);
        },
        loadformInitResponse(obj) {
            obj;
            // console.log('loadformInitResponse => ', obj.data);
            if (obj.data.form && obj.data.form_questions) {
                this.form = obj.data.form;
                this.form_questions = obj.data.form_questions;

                this.startSwiper();
                // console.log('FORM',this.form);
                // console.log('FORM QUESTIONS',this.form_questions);
            }
            this.$app.wait(false);
        },
        loadformInitError(obj) {
            obj;
            // console.log('error => ', obj);
            this.$app.wait(false);
        },
        slideChangeHandler() {
            // console.log('slideChangeHandler');
            const index = this.form__swiper.realIndex;
            const currentSlide = this.form__swiper.slides[index];
            const buttNext = document.getElementById('formNext');
            const buttSend = document.getElementById('formSend');
            if (!this.restart_) {

                this.validateStep(currentSlide);

            }
            console.log('this.form__swiper.isEnd => ', this.form__swiper.isEnd, index);
            if (this.form__swiper.isEnd) {
                this.last_page = true;
                buttNext.classList.add('buttonHidden');
                buttSend.classList.remove('buttonHidden');
            } else {
                buttNext.classList.remove('buttonHidden');
                buttSend.classList.add('buttonHidden');
            }
            this.restart_ = false;
        },
        validateStep(currentStep) {
            console.log('validateStep');
            // 1 - acha o input ou textarea mais proximo do currentSlide (child)
            let children = currentStep.querySelectorAll('.child');
            let goodtogo = [];
            // 2 - loop nos children
            for (var i = 0; i < children.length; i++) {
                let tagName = children[i].tagName.toLowerCase();
                // 2.1 - se for textarea
                if (tagName === 'textarea') {
                    let currentLength = children[i].value.length;
                    if (currentLength > 0) {
                        goodtogo.push(children[i].value);
                    }
                }
                // 2.2 - se for input
                else if (tagName === 'input') {
                    let type = children[i].getAttribute('type').toLowerCase();
                    // 2.2.1 se for checkbox ou radio
                    if (type === 'checkbox' || type === 'radio') {
                        if (children[i].checked) {
                            goodtogo.push(children[i].value);
                        }
                    }
                }
            }
            if (goodtogo.length > 0) {
                this.nextButtonStatus(true);
            } else {
                this.nextButtonStatus(false);
            }
        },
        validateField(el) {
            // console.log('alidateField');
            // console.log(this.form_questions[this.form__swiper.activeIndex].options);


            let tagName = el.tagName.toLowerCase();
            // textarea
            if (tagName == 'textarea') {
                let currentLength = el.value.length;
                if (currentLength <= 0) {
                    this.nextButtonStatus(false);
                } else {
                    this.nextButtonStatus(true);
                }
            }
            // input
            else if (tagName == 'input') {
                let type = el.getAttribute('type').toLowerCase();
                // checkbox
                if (type === 'checkbox' || type === 'radio') {

                    if (this.form.type == 'Quiz') {
                        let correct = this.form_questions[this.form__swiper.activeIndex].options.filter(q => q.correta === true);
                        this.form_correct[this.form__swiper.activeIndex] = (correct && (correct[0].name == el.value)) ? true : false;
                        this.form_selected[this.form__swiper.activeIndex] = this.form_questions[this.form__swiper.activeIndex].options.filter(q => q.name == el.value)[0];
                    }

                    // 1 - pega o wrapper de todos, pega os filhos
                    let parent = el.parentNode.parentNode.parentNode;
                    let children = parent.querySelectorAll('.child');
                    let selected = [];
                    // 2 - loop nos filhos para ver se pelo menos um ta check, se sim adiciona no nosso selected
                    for (var i = 0; i < children.length; i++) {
                        if (children[i].checked) {
                            selected.push(children[i].value);
                        }
                    }
                    // 3 - se tiver checkado mais que 0, ok
                    if (selected.length > 0) {
                        this.nextButtonStatus(true);
                    } else {
                        this.nextButtonStatus(false);
                    }
                }
            }
        },
        validateForm() {
            this.$app.wait(false);
            console.log('VALIDATE')
            return true;
        },
        submitForm() {
            this.$app.wait(true);
            let form = document.getElementById('formForm');
            if (this.validateForm) {
                let form_data = new FormData(form);
                let headers = {
                    "Content-Type": "application/json;charset=UTF-8",
                    Access: "application/json",
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
                };
                axios
                    .post(this.$url_api + 'api/quest-step-form', form_data, {headers: headers})
                    .then(response => this.loadSubmitFormResponse(response))
                    .catch(error => this.loadSubmitFormError(error.response));
            } else {
                console.log('VALIDATION NOT OK');
            }
            return false;
        },
        loadSubmitFormResponse(obj) {
            this.$app.wait(false);
            if (obj.data.answered && obj.data.answered === 'success') {
                this.quiz_error = null;
                if (this.form.type === 'Quiz') {
                    let count_total = this.form_correct.length;
                    let count_error = this.form_correct.filter(x => (x == false)).length;
                    let count_correct = this.form_correct.filter(x => (x == true)).length;
                    let min_answers
                    if (this.$parent.current_step.min_correct_answers===-1){
                        if (count_error > 0) {
                            this.quiz_error = 'Acertou ' + (count_total - count_error) + ' de ' + count_total + '\n';
                            this.quiz_error += (this.$parent.current_step.quiz_error_message ? this.$parent.current_step.quiz_error_message : '');
                        }
                    } else if (this.$parent.current_step.min_correct_answers > 0) {
                        if (count_correct < this.$parent.current_step.min_correct_answers) {
                            this.quiz_error = 'Acertou ' + (count_total - count_error) + ' de ' + count_total + '\n';
                            this.quiz_error += (this.$parent.current_step.quiz_error_message ? this.$parent.current_step.quiz_error_message : '');
                        }
                    } else if(this.$parent.current_step.min_correct_answers !== 0) {
                        if (this.form.min_answers_to_badge > 0 || this.form.min_answers_to_ticket > 0) {
                            min_answers = Math.min(this.form.min_answers_to_badge, this.form.min_answers_to_ticket) > 0 ? Math.min(this.form.min_answers_to_badge, this.form.min_answers_to_ticket) : Math.max(this.form.min_answers_to_badge, this.form.min_answers_to_ticket)
                            if (count_correct < min_answers) {
                                this.quiz_error = 'Acertou ' + (count_total - count_error) + ' de ' + count_total + '\n';
                                this.quiz_error += (this.$parent.current_step.quiz_error_message ? this.$parent.current_step.quiz_error_message : '');
                            }
                        } else if (count_error > 0 && this.form.min_answers_to_badge == 0 && this.form.min_answers_to_ticket == 0) {
                            this.quiz_error = 'Acertou ' + (count_total - count_error) + ' de ' + count_total + '\n';
                            this.quiz_error += (this.$parent.current_step.quiz_error_message ? this.$parent.current_step.quiz_error_message : '');
                        }
                    }
                }
                if (!this.quiz_error) {
                    this.progress()
                }
            }
        },
        loadSubmitFormError(obj) {
            obj;
            // console.log('error => ', obj);
            this.$app.wait(false);
        },
        progress() {
            this.$app.wait(false);
            if (!this.show_answer_sheet && this.form && this.form.type === 'Quiz' && this.$parent.current_step.show_answer_sheet) {
                this.show_answer_sheet = true;
            } else {
                this.$parent.userProgress();
            }
        }
    },
    created() {
    },
    mounted() {
    },
    unmounted() {
    },

}
</script>
<style>
#quiz_error {
    color: rgb(255, 255, 255);
}

#quest-form label {
    font-family: 'Titillium Web', sans-serif;
    font-size: 18px !important;
    font-weight: 400;
}

/*
#quest-form ::-webkit-scrollbar
{
    width: 32px;
}
#quest-form ::-webkit-scrollbar-track 
{
    background-color: rgba(255, 255, 255, 0.5);;
    border-radius: 14px;
}
#quest-form ::-webkit-scrollbar-thumb
{
    background: #33BCEE; 
    border: 6px solid transparent;
    border-radius: 20px;
    background-clip: content-box;
}
#quest-form ::-webkit-scrollbar-thumb:hover
{
    border: 6px solid transparent;
    border-radius: 20px;
    background-clip: content-box;
}

@media only screen and (max-width: 600px)
{
    #quest-form ::-webkit-scrollbar
    {
        width: 10px;
    }
    #quest-form ::-webkit-scrollbar-track 
    {
        background-color: rgba(255, 255, 255, 0.5);;
        border-radius: 6px;
    }
    #quest-form ::-webkit-scrollbar-thumb
    {
        background: #33BCEE; 
        border: 2px solid transparent;
        border-radius: 14px;
        background-clip: content-box;
    }
    #quest-form ::-webkit-scrollbar-thumb:hover
    {
        border: 2px solid transparent;
        border-radius: 14px;
        background-clip: content-box;
    }
}

*/


/*----- checkbox -----*/
.styled-checkbox {
    /* opacity: 0;
    position: absolute;
    position: relative; */
    display: none;
}

.styled-checkbox + label {
    cursor: pointer;
    line-height: 31px;
    padding: 0;
    position: relative;
}

.styled-checkbox + label:before {
    background: #fff;
    border: 2px solid #D9D9D9;
    border-radius: 50px;
    content: '';
    display: inline-block;
    min-height: 24px;
    max-height: 24px;
    margin-right: 10px;
    vertical-align: top;
    min-width: 24px;
    max-width: 24px;
}

.styled-checkbox:focus + label:before {
    border: solid 2px #33BCEE;
    box-shadow: 0 0 4px rgba(11, 157, 182, .4);
}

.styled-checkbox:hover + label:before {
    /*background: #0b9db6;*/
    /*box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);*/
}

.styled-checkbox:focus + label:before {
    /*box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);*/
}

.styled-checkbox:checked + label:before {
    /*background: #f5f5f5;*/
}

.styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled + label:before {
    background: #f5f5f5;
    border-color: #e5e5e5;
    box-shadow: none;
}

.styled-checkbox:checked + label::after {
    content: '';
    position: absolute;
    top: 18px;
    left: 18px;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    height: 6px;
    width: 13px;
    transform: rotate(-45deg);
}

.check input, .check .label {
    color: #777 !important;
    display: inline-block !important;
    font-family: inherit !important;
    letter-spacing: 0 !important;
    width: auto !important;
}

.check:nth-child(2) {
    margin-top: 8px;
}

/*----- \checkbox\ -----*/
</style>